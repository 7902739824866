.html {
    font-family: "American Typewriter", monospace;
}

.body {
    font-family: "American Typewriter", monospace;
    font-size: 16px;
    line-height: 1.8em;
    letter-spacing: .02em;
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    background-color: transparent;
    color: #333;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
}

.overflow-wrapper {
    height: 100%;
    min-height: 100vh;
    background-color: transparent;
    transition: background-color 100ms linear 0s;
}

.site-container {
    opacity: 1;
    height: 100%;
    transition-property: opacity;
    transition-duration: 100ms;
    transition-timing-function: linear;
    transition-delay: 100ms;
    position: relative;
    width: auto;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
}

.site-header {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 140ms;
    transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
    transition-delay: 0s;
}

.logo-wrapper {

}

.site-title {
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    line-height: 1.2em;
    text-transform: capitalize;
    letter-spacing: 0;
    text-decoration: none;
    font-weight: 550;
    font-style: normal;
}

.site-tagline {
    font-family: "Proxima Nova", sans-serif;
    font-size: 13px;
    line-height: 2em;
    text-transform: none;
    letter-spacing: .03em;
    font-weight: 400;
    font-style: normal;
    color: #aaa;
}

.content-container {
    opacity: 1;
    -webkit-transition: opacity .78s;
    -moz-transition: opacity .78s;
    -ms-transition: opacity .78s;
    -o-transition: opacity .78s;
    transition: opacity .78s;
}

.site-page {
    max-width: 100%;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    margin-bottom: 5vw;
    /*padding-right: 5vw;*/
    /*padding-left: 5vw;*/
}

.page-text-wrapper {
    text-align: center;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    display: block;
}

.index-section {
    border-right: solid 12.5px #fff;
    box-sizing: border-box;
    width: 50%;
    float: left;
    overflow: hidden;
}

.index-item {
    background-color: white ! important;
    height: 50vh;
    position: relative;
    transition: background-color 140ms cubic-bezier(.33,0,.2,1);
}

.index-item-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    background: transparent;
}

.index-item-text-wrapper {
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 300ms cubic-bezier(.33,0,.2,1);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 4vw;
    right: 4vw;
    bottom: 4vw;
    left: 4vw;
    margin: auto;
    width: calc(100% - 8vw);
    height: calc(100% - 8vw);
    pointer-events: none;
}

.index-item-title {
    font-size: 25px;
    visibility: visible;
    line-height: 1.5em;
    text-transform: none;
    letter-spacing: -.02em;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0;
    display: inline-block;
    max-width: 70%;
}
